export const homeObjOne = {
  lightBg: false,
  lightText: false,
  lightTopLine: false,
  lightTextDesc:false,
  topLine: 'Malco Enterprises Pvt Ltd',
  headline: 'We are an ISO 9001:2015 certified manufacturers and wholesalers of a wide variety of housekeeping products',
  description:
    'We produce products such as mops, brooms,cleaning chemicals, home essentials etc., under the registered trademark of Malco.',
  buttonLabel: 'We have a wide number of need based choices (domestic & commercial) in each product',
  imgStart: '',
  img: require('../../images/sale.jpg'),
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: '',
  headline: '',
  description:
    'We are concentrating solely on long-term relationships with our customers through an optimized pricing approach driven by demands.',
  buttonLabel: ' We take utmost care from the procurement of raw materials to the delivery of our products.',
  imgStart: 'start',
  img: require('../../images/custo1.jpg'),
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: false,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: 'To Join and Grow With US',
  headline: 'Contact : 80560 90033',
  description:
    "E-mail : malcochennai78@gmail.com",
  buttonLabel: '',
  imgStart: 'start',
  img: require('../../images/join.png'),
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'As we are always transparent with our customers, we stick to the specifications of our products in terms of quality, durability and user friendliness.',
  description:
    'Currently we are operating in and around Chennai having over 4000 customers from mini grocery stores to hypermarkets.',
  buttonLabel: '',
  imgStart: '',
  img: require('../../images/trans1.png'),
  alt: 'Vault'
};
export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'We are on a mission to provide need-based FMCG products at affordable prices to customers across Tamil Nadu.',
  description:
    'Currently we are operating in and around Chennai having over 4000 customers from mini grocery stores to hypermarkets.',
  buttonLabel: '',
  imgStart: 'start',
  img: require('../../images/msv1.png'),
  alt: 'Vault'
};
