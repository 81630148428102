import React from 'react'
import './HeroSection.css';
import img from '../../images/logo4.png';
import img1 from '../../images/iso_mal2.png';
import img3 from '../../images/eco1.png';
import img2 from '../../images/iaf1.png';
import vid from '../../images/Malco_title_3.mp4';

import { Typewriter } from "react-simple-typewriter";

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src={vid} autoPlay loop muted />
    
      <img src={img}/>
      
      <p><Typewriter words={[" For The Employees", " By The Employees"]} loop cursor cursorStyle='|' typeSpeed={70} deleteSpeed={40} delaySpeed={1000} /></p>
      
    </div>
  );
}

export default HeroSection;
