import React from 'react';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import { InfoSection, Pricing,InfoSectionHero,Card,HeroSection,MainCard } from '../../components';

function Home() {
  return (
    <>
      <HeroSection/>
    </>
  );
}

export default Home;
