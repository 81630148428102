import React from 'react';
import { InfoSection,Cards} from '../../components';
import { homeObjOne, homeObjTwo } from './Data';

function Products() {
  return (
    <>
      <Cards/>
      
    </>
  );
}

export default Products;
