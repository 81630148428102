import React from 'react';
import { InfoSection } from '../../components';
import { homeObjFive, homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from './Data';

function SignUp() {
  return (
    <>
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjFour} />
      <InfoSection {...homeObjFive} />
    </>
  );
}

export default SignUp;
