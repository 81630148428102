import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes,FaAtlassian } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../globalStyles';
import img1 from '../../images/iso_mal2.png';
import img3 from '../../images/eco1.png';
import img2 from '../../images/iaf1.png';

import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavMenus,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavBtnLink,
  SocialIcon
} from './Navbar.elements';
import img from '../../images/logo_mal.png';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  let iconStyles = { color: "black"};
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to='/' onClick={closeMobileMenu}>
            <img src={img} />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes style={iconStyles}/> : <FaBars style={iconStyles}/>}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to='/' onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/products' onClick={closeMobileMenu}>
                  Products
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/careers' onClick={closeMobileMenu}>
                  Careers
                </NavLinks>
              </NavItem>
             
              <NavItem>
                <NavLinks to='/about-us' onClick={closeMobileMenu}>
                  About Us
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavMenus>
            <SocialIcon>
              <img src={img1}/>
            </SocialIcon>
            <SocialIcon>
              <img src={img2}/>
              </SocialIcon>
              <SocialIcon>
              <img src={img3}/>
              </SocialIcon>
            </NavMenus>  
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
