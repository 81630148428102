export default [
	{ id: '1', imageName: '1.png', tag: 'mops' },
	{ id: '3', imageName: '3.png', tag: 'mops' },
	{ id: '5', imageName: '5.png', tag: 'mops' },
	{ id: '7', imageName: '7.png', tag: 'mops' },
	{ id: '9', imageName: '9.png', tag: 'mops' },
	{ id: '10', imageName: '11.png', tag: 'mops' },
	{ id: '14', imageName: '15.png', tag: 'mops' },
	{ id: '16', imageName: '17.png', tag: 'mops' },
	{ id: '2', imageName: '2.png', tag: 'mops' },
	{ id: '4', imageName: '4.png', tag: 'mops' },
	{ id: '6', imageName: '6.png', tag: 'mops' },
	{ id: '8', imageName: '8.png', tag: 'mops' },
	{ id: '68', imageName: '10.png', tag: 'mops' },
	{ id: '11', imageName: '12.png', tag: 'mops' },
	{ id: '13', imageName: '14.png', tag: 'mops' },
	{ id: '15', imageName: '16.png', tag: 'mops' },
	{ id: '17', imageName: '18.png', tag: 'mops' },
	{ id: '18', imageName: '19.png', tag: 'brooms' },
	{ id: '71', imageName: '20.png', tag: 'brooms' },
	{ id: '19', imageName: '21.png', tag: 'brooms' },
	{ id: '20', imageName: '22.png', tag: 'brooms' },
	{ id: '21', imageName: '23.png', tag: 'brooms' },
	{ id: '22', imageName: '24.png', tag: 'brooms' },
	{ id: '23', imageName: '25.png', tag: 'Garbage-bags' },
	{ id: '24', imageName: '26.png', tag: 'Garbage-bags' },
	{ id: '25', imageName: '27.png', tag: 'Garbage-bags' },
	{ id: '26', imageName: '28.png', tag: 'Garbage-bags' },
	{ id: '27', imageName: '29.png', tag: 'chemicals' },
	{ id: '28', imageName: '30.png', tag: 'chemicals' },
	{ id: '29', imageName: '31.png', tag: 'chemicals' },
	{ id: '30', imageName: '32.png', tag: 'chemicals' },
	{ id: '31', imageName: '33.png', tag: 'chemicals' },
	{ id: '32', imageName: '34.png', tag: 'chemicals' },
	{ id: '33', imageName: '35.png', tag: 'chemicals' },
	{ id: '34', imageName: '36.png', tag: 'chemicals' },
	{ id: '35', imageName: '37.png', tag: 'miscellaneous-utilities' },
	{ id: '36', imageName: '38.png', tag: 'miscellaneous-utilities' },
	{ id: '37', imageName: '39.png', tag: 'miscellaneous-utilities' },
	{ id: '38', imageName: '40.png', tag: 'miscellaneous-utilities' },
	{ id: '39', imageName: '41.png', tag: 'miscellaneous-utilities' },
	{ id: '40', imageName: '42.png', tag: 'Brushs' },
	{ id: '41', imageName: '43.png', tag: 'Brushs' },
	{ id: '42', imageName: '44.png', tag: 'Brushs' },
	{ id: '43', imageName: '45.png', tag: 'Brushs' },
	{ id: '44', imageName: '46.png', tag: 'Brushs' },
	{ id: '45', imageName: '47.png', tag: 'Brushs' },
	{ id: '46', imageName: '48.png', tag: 'Brushs' },
	{ id: '47', imageName: '49.png', tag: 'miscellaneous-utilities' },
	{ id: '48', imageName: '50.png', tag: 'miscellaneous-utilities' },
	{ id: '49', imageName: '51.png', tag: 'miscellaneous-utilities' },
	{ id: '50', imageName: '52.png', tag: 'miscellaneous-utilities' },
	{ id: '51', imageName: '53.png', tag: 'miscellaneous-utilities' },
	{ id: '52', imageName: '54.png', tag: 'miscellaneous-utilities' },
	{ id: '53', imageName: '55.png', tag: 'miscellaneous-utilities' },
	{ id: '54', imageName: '56.png', tag: 'miscellaneous-utilities' },
	{ id: '55', imageName: '57.png', tag: 'miscellaneous-utilities' },
	{ id: '56', imageName: '58.png', tag: 'miscellaneous-utilities' },
	{ id: '57', imageName: '59.png', tag: 'miscellaneous-utilities' },
	{ id: '58', imageName: '60.png', tag: 'miscellaneous-utilities' },
	{ id: '59', imageName: '61.png', tag: 'miscellaneous-utilities' },
	{ id: '60', imageName: '62.png', tag: 'miscellaneous-utilities' },
	{ id: '61', imageName: '63.png', tag: 'miscellaneous-utilities' },
	{ id: '62', imageName: '64.png', tag: 'miscellaneous-utilities' },
	{ id: '63', imageName: '65.png', tag: 'miscellaneous-utilities' },
	{ id: '64', imageName: '66.png', tag: 'miscellaneous-utilities' },
	{ id: '65', imageName: '67.png', tag: 'miscellaneous-utilities' },
	{ id: '66', imageName: '68.png', tag: 'miscellaneous-utilities' },
	{ id: '67', imageName: '69.png', tag: 'miscellaneous-utilities' },
	{ id: '69', imageName: '70.png', tag: 'miscellaneous-utilities' },
	{ id: '70', imageName: '71.png', tag: 'miscellaneous-utilities' },
	{ id: '71', imageName: '72.png', tag: 'miscellaneous-utilities' },
	{ id: '72', imageName: '73.png', tag: 'miscellaneous-utilities' },
	{ id: '73', imageName: '74.png', tag: 'miscellaneous-utilities' },
	{ id: '74', imageName: '75.png', tag: 'miscellaneous-utilities' },
	{ id: '75', imageName: '76.png', tag: 'miscellaneous-utilities' },
];
