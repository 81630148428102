export const homeObjOne = {
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join and Grow with us',
  headline: 'We are always looking for Dynamic and Result Oriented Marketing Executives and Team Leaders to promote our business',
  description:
  'We offer good Salary and huge Incentives along with other benifits.',
  buttonLabel: 'Contact : 80560 90033',
  email:'Email : malcochennai78@gmail.com',
  imgStart: '',
  img: require('../../images/join.png'),
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '100% Secure',
  headline: 'Long-Term Customer Relationship',
  description:
    'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Delivery',
  headline: 'Customer Delivery',
  description:
    "Take the orders and deliver to the Customers on timely basis. Have to drive a truck for delivery",
  buttonLabel: 'petrol and food convense included',
  imgStart: 'start',
  img: require('../../images/delivery.jpg'),
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Data Analytics',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};
