import React, { useEffect, useState } from 'react';
import './Menu.css';
import images from './data';
import { SRLWrapper } from 'simple-react-lightbox';
import {FaAngleDown} from 'react-icons/fa';

const options = {
	// settings: {
	// 	overlayColor: 'rgb(25, 136, 124)',
	// 	autoplaySpeed: 1500,
	// 	transitionSpeed: 900
	// },
	// buttons: {
	// 	backgroundColor: 'red',
	// 	iconColor: 'rgba(126, 172, 139, 0.8)'
	// },
	// caption: {
	// 	captionColor: '#a6cfa5',
	// 	captionFontFamily: 'Raleway, sans-serif',
	// 	captionFontWeight: '300',
	// 	captionTextTransform: 'uppercase'
	// },
	// progressBar: {
	// 	height: '20px',
	// 	fillColor: 'blue',
	// 	backgroundColor: 'white'
	// }
};

function Menu() {
	const [tag, setTag] = useState('mops');
	const [filteredImages, setFilteredImages] = useState([]);
	const [icon, setIcon] = useState([false]);

	useEffect(
		() => {
			tag === 'all' ? setFilteredImages(images) : setFilteredImages(images.filter(image => image.tag === tag));
		},
		[tag]
	);

	return (
		<div className="App">
			<div className="tags">
				<TagButton name="mops" tagActive={tag === 'mops' ? true : false} handleSetTag={setTag} />
				<TagButton name="brooms" tagActive={tag === 'brooms' ? true : false} handleSetTag={setTag} /> 
				<TagButton name="Garbage-bags" tagActive={tag === 'Garbage-bags' ? true : false} handleSetTag={setTag} />
				<TagButton name="Brushs" tagActive={tag === 'Brushs' ? true : false} handleSetTag={setTag} />
				<TagButton name="chemicals" tagActive={tag === 'chemicals' ? true : false} handleSetTag={setTag} />
				<TagButton name="miscellaneous-utilities" tagActive={tag === 'miscellaneous-utilities' ? true : false} handleSetTag={setTag} />
			</div>
			<SRLWrapper options={options}>
				<div className="container">
					{filteredImages.map(image => (
						<div key={image.id} className="image-card">
							<a href={`/images/${image.imageName}`}>
								<img className="image" src={`/images/${image.imageName}`} alt="" />
							</a>
						</div>
					))}
				</div>
			</SRLWrapper>
		</div>
	);
}

const TagButton = ({ name, handleSetTag, tagActive }) => {
	return (
		<button className={`tag ${tagActive ? 'active' : null}`} onClick={() => handleSetTag(name)}>
			{name.toUpperCase()} {tagActive? <FaAngleDown/>:''}
		</button>
	);
};

export default Menu;
