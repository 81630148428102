import React from 'react';
import { Button } from '../../globalStyles';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';
import img from '../../images/malco_logo.png';
import img1 from '../../images/eco1.png';
import img2 from '../../images/iaf1.png';
import img3 from '../../images/iso_mal2.png';

function Footer() {
  return (
    <FooterContainer>
     
     
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            <img src={img} />
          </SocialLogo>
          <WebsiteRights>Malco Enterprises Pvt Ltd © 2022</WebsiteRights>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
