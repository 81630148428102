export const homeObjOne = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'A Leading FMCG Company',
  headline: 'A Company Which is',
  description:
    'Our Company`s growth is by the employee and we provide opportunities for employee`s growth',
  buttonLabel: 'Know More about Us',
  imgStart: '',
  img: require('../../images/fmcg-3.png'),
  alt: 'Credit Card',
  start: '',
  path:'/sign-up',
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Instant Setup',
  headline: 'Extremely quick onboarding process',
  description:
    "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/svg-2.svg'),
  alt: 'Vault',
  start: ''
};

export const homeObjThree = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'What is an FMCG Company?',
  headline:
    'There are Fast Moving Consumer Goods which are used in our Daily Life',
  description:
    "You can check the opputunities in Careers Page",
  buttonLabel: 'Oppurtunities',
  imgStart: 'start',
  img: require('../../images/fmcg1.jpg'),
  alt: 'Vault',
  start: 'true',
  path:'/services',
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Secure Database',
  headline: 'All your data is stored on our secure server',
  description:
    'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-3.svg'),
  alt: 'Vault',
  start: 'true'
};
 